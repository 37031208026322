<template>
  <div>
    <va-card title="Monitoring" style="height: fit-content;">
      <div class="flex row text-center" style=" display: flex; flex-direction: column; width: 400px; margin: auto;">
        <div>
          <img src="@/assets/monitor.png">
        </div>
        <div v-if="role === 'ADMIN' || role === 'SUPPORT'">
          <span class="mt-3 va-form-label va-form-required-label">Organization</span>
          <va-select placeholder="Select Organization" v-model="org" textBy="org_name" searchable track-by="id"
            :options="orgLists" :error="OrgError" />
        </div>
        <va-button type="submit" @click.prevent="monitor(org)">CONNECT</va-button>
      </div>
    </va-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'
Vue.use(vueResource)
export default {
  created () {
    this.getAllOrg()
  },
  data () {
    return {
      role: this.$cookies.get('roles'),
      org: '',
      orgLists: [],
      OrgError: false,
    }
  },
  methods: {
    getAllOrg () {
      var Roles = ['OPERATOR', 'HEPI_OPERATOR']
      this.$http.get(config.menu.host + '/org').then(response => {
        this.orgLists = response.data.map((items, index) => {
          items.id = index + 1
          return items
        }).filter(item => {
          return Roles.includes(item.org_type)
        })
      })
    },
    monitor (org) {
      if (this.role === 'ADMIN' || this.role === 'SUPPORT') {
        if (!org) {
          this.OrgError = true
          return
        } else {
          this.OrgError = false
        }
      }
      const OrgName = (this.role == 'OPERATOR') ? this.$cookies.get('orgName') : org.org_name
      const OrgId = (this.role == 'OPERATOR') ? this.$cookies.get('org-id') : org.org_id
      var gridContent = ['serial_no', 'ip', 'mac', 'latency', 'screen', 'channel']
      const url = `https://monitoring.skie.tv?org_id=${OrgId}&org_name=${OrgName}&pointing_url=monitoring.skie.tv&grid=${gridContent}`
      window.open(url, '_blank')
    },
  },
}
</script>
